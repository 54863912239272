<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    offset-x
    offset-y
  >
    <template v-slot:activator="{ on }">
      <div
        class="tw-flex tw-px-2 tw-items-center tw-cursor-pointer"
        v-on="on"
      >
        <div :class="['tw-px-2 tw-hidden md:tw-block', 'c-gray-text']">{{ user }} <v-icon color="gray">mdi-chevron-down</v-icon></div>
        <avatar-icon :username="user" :size="32" color="white" backgroundColor="#114cac" />
        <div class="tw-absolute tw-block md:tw-hidden tw-mt-6 tw-ml-6">
          <v-icon color="gray">mdi-chevron-down</v-icon>
        </div>
      </div>
    </template>

    <v-card>
      <v-list>
        <v-list-item>
          <v-list-item-action>
            <router-link :to="{name: 'Dashboard'}"><v-icon color="primary">mdi-apps</v-icon> Dashboard </router-link>
          </v-list-item-action>
        </v-list-item>
        <v-list-item>
          <v-list-item-action>
            <p>Update Profile</p>
          </v-list-item-action>
        </v-list-item>
        <v-list-item>
          <v-list-item-action>
            <a @click="onLogout"><v-icon color="primary">mdi-login</v-icon> Logout</a>
          </v-list-item-action>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
    </v-card>
  </v-menu>
</template>

<script>
import { mapState } from 'vuex';
import AvatarIcon from 'vue-avatar';

export default {
  name: 'Avatar',
  data: () => ({
    menu: null,
  }),
  components: { AvatarIcon },
  computed: {
    ...mapState({
      user: (state) => state.auth.userInfo.agentName,
    }),
  },
  methods: {
    async onLogout() {
      await this.$store.dispatch('auth/signOut').then(() => {
        this.$router.push({ name: 'SignIn' });
      });
    },
  },
};
</script>
