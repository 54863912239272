<template>
  <v-app id="inspire">
    <v-main>
      <div class="tw-w-full tw-flex tw-h-full tw-max-h-screen tw-py-0 tw-bg-gray-300">
        <div class="tw-w-full tw-flex tw-flex-row tw-rounded-lg tw-my-6 tw-mx-5 lift">
          <div
            class="
              image
              lg:tw-block
              md:tw-w-full
              tw-h-auto tw-flex tw-justify-center
            "
          >
          </div>
          <div class="
              tw-h-auto tw-px-6
              md:tw-px-24
              tw-py-20
              tw-w-full
              tw-flex tw-flex-wrap tw-items-center tw-justify-center
            "
            id="login"
          >
            <div class="tw-w-full elevation-3 tw-mt-8 tw-py-4 box-shadow tw-rounded-2xl">
              <div class="tw-w-full tw-flex tw-justify-center">
                <img
                  src="/apalife-logo.svg"
                  class="tw-w-24 md:tw-w-32 tw-mt-4 md:tw-mt-6 tw-shadow-sm"
                  alt="logo"
                />
              </div>
              <v-form v-model="isValid" ref="form" @submit.prevent="submitForm">
                <v-card-text class="tw-px-8 md:tw-px-10">
                  <v-text-field
                    id="username"
                    class="my-5 box"
                    label="Agent code"
                    type="number"
                    name="username"
                    v-model="form.username"
                    :rules="[required('Agent code')]"
                  >
                    <v-icon slot="prepend" color="primary">mdi-account</v-icon>
                  </v-text-field>
                  <v-text-field
                    id="password"
                    label="Password"
                    :type="passwordField"
                    v-model="form.password"
                    :rules="[required('Password')]"
                  >
                    <v-icon slot="prepend" color="primary">mdi-lock</v-icon>
                    <v-icon
                      slot="append"
                      color="primary"
                      class="tw-cursor-pointer"
                      @click="
                        passwordField =
                          passwordField === 'password' ? 'text' : 'password'
                      "
                      >{{
                        passwordField === 'password' ? 'mdi-eye' : 'mdi-eye-off'
                      }}</v-icon
                    >
                  </v-text-field>
                </v-card-text>

                <v-card-actions class="col-sm-12 tw-px-4 md:tw-px-10">
                  <v-btn
                    block
                    large
                    id="signIn"
                    type="submit"
                    color="primary"
                    class="my-3"
                    @click="loading = true"
                    :loading="loading"
                    :disabled="!isValid"
                  >
                    Login
                  </v-btn>
                </v-card-actions>
              </v-form>
              <div class="tw-grid tw-justify-items-stretch" >
            <div class="tw-justify-self-end tw-mr-7">
              <v-btn text class="primary--text tw-font-bold" router to="/reset-password">Forgot Password ?</v-btn>
            </div>
            </div>
            </div>
            <div class="tw-w-full">
              <div
                class="
                  tw-w-full tw-flex tw-items-center
                  c-gray-text
                  tw-text-center
                "
              >
                <div class="tw-w-5/12">
                  <hr />
                </div>
                <div class="tw-w-2/12">OR</div>
                <div class="tw-w-5/12">
                  <hr />
                </div>
              </div>
              <get-quote :query="query" />
            </div>
          </div>
        </div>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import validations from '../../utils/validations';
import GetQuote from '@/components/GetQuote';

export default {
  name: 'SignIn',
  metaInfo: {
    title: 'Sign In',
  },
  components: { GetQuote },
  data() {
    return {
      form: {
        username: '',
        password: '',
      },
      ...validations,
      loading: false,
      isValid: false,
      query: '',
      passwordField: 'password',
    };
  },
  methods: {
    async submitForm() {
      const formData = this.form;
      await this.$store.dispatch('auth/login', formData);
      await this.$nextTick();
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.image {
  background-image: url('/images/smileyLarge.jpeg');
  background-repeat: no-repeat;
  background-position: left top;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  background-size: cover;
}
.box-shadow {
  box-shadow: 9.91px 9.91px 15px #D9DADE, -9.91px -9.91px 15px #FFFFFF;
}
.lift {
  background: #EEF0F4;
  box-shadow: 8.11px 8.11px 15px #9EA0A2, -8.11px -8.11px 15px #FFFFFF;
}

</style>
