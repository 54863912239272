<template>
  <v-app>
  <div >
    <keep-alive  include="ELimuPlan, WholeLifePlan">
      <router-view :key="$route.fullPath"/>
    </keep-alive>
    <v-snackbar v-model="snackbar.showing"  :color="snackbar.color" bottom>
        <div class="tw-flex tw-justify-between tw-items-center">
          <span> {{ snackbar.text }}</span>
          <v-btn depressed text @click="snackbar.showing = false">close</v-btn>
        </div>
      </v-snackbar>
  </div>
  </v-app>
</template>

<script>

import { mapState } from 'vuex';
import { USER } from '@/utils/const';

export default {
  name: 'App',
  data: () => ({
    //
  }),
  computed: {
    ...mapState({
      snackbar: (state) => state.auth.snackbar,
    }),
  },
  beforeCreate() {
    if (this.$store.getters['auth/isAuthenticated'] && (this.$cookies.get(USER) === null)) {
      this.$store.dispatch('auth/signOut').then(() => {
        this.$router.push({ name: 'SignIn' });
      });
    }
  },
  created() {
    window.addEventListener('offline', () => {
      this.$store.commit('SNACKBAR', {
        showing: true,
        text: 'Seems you\'re offline!, No Internet',
        color: 'error',
      }, { root: true });
    });
    window.addEventListener('online', () => {
      this.$store.commit('SNACKBAR', {
        showing: true,
        text: 'You\'re back online, Online',
        color: 'success',
      }, { root: true });
      if (window.location.pathname === '/no-internet') {
        this.$router.go(-1);
      }
    });
  },
};
</script>
