<template>
  <div>
    <v-list dense>
      <div class="tw-px-4 tw-py-3 md:tw-py-10 tw-px-16 tw-mb-8">
        <router-link
          class="tw-flex tw-justify-center tw-flex-col tw-p-0 md:tw-p-2"
          :to="{ name: 'Dashboard' }"
        >
          <img src="/logo.png" alt="APA Life Logo" />
        </router-link>
      </div>
      <template v-for="item in items">
        <v-list-item
          :key="item.text"
          link
          :to="item.link"
          active-class="nav-active"
          class="tw-mt-0 md:tw-mt-5"
        >
          <v-list-item-action>
            <v-icon color="#fff">{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="tw-text-white tw-font-bold">
              {{ item.text }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
  </div>
</template>

<script>
export default {
  name: 'Drawer',
  data: () => ({
    items: [
      { icon: 'mdi-apps', text: 'Dashboard', link: { name: 'Dashboard' } },
      { icon: 'mdi-store', text: 'Products', link: { name: 'Products' } },
      {
        icon: 'mdi-file-document',
        text: 'Proposals',
        link: { name: 'Proposals' },
      },
      {
        icon: 'mdi-shield-check',
        text: 'Policies',
        link: { name: 'Policies' },
      },
    ],
  }),
};
</script>

<style scoped>
.tw-font-bold {
  font-weight: 600 !important;
}
.nav-active {
  border-left: #ff5252 8px solid;
}
</style>
